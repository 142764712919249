<nav class="navbar sticky-top navbar-dark bg-dark p-2 pe-2">
    <a class="logo" routerLink="/">
        <img class="logo me-2 p-0" src="./assets/small_logo.png" alt="Logo">
        <span class="title">SNOMED CT {{extension?.extensionName}} Extension
            <img *ngIf="extension?.extensionFlag" src="./assets/flags/{{extension?.extensionFlag}}.png" alt="{{extension?.extensionName}}">
        </span>
    </a>

    <ng-container [ngSwitch]="environment">
        <div *ngSwitchCase="'local'" class="banner text-white local"></div>
        <div *ngSwitchCase="'dev'" class="banner text-white dev"></div>
        <div *ngSwitchCase="'uat'" class="banner text-white uat"></div>
        <div *ngSwitchCase="'training'" class="banner text-white training"></div>
        <div *ngSwitchDefault class="banner text-white d-none"></div>
    </ng-container>

    <span class="pe-5">
        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{language?.languageName}}<img *ngIf="language?.languageFlag" class="p-2 ps-4" src="./assets/flags/{{language?.languageFlag}}.png" alt="{{language?.languageName}}">
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li *ngFor="let lang of configuration?.languages">
                    <a class="dropdown-item" [routerLink]="[lang.languageCode]" (click)="changeLanguage(lang.languageCode)">{{lang.languageName}}<img class="p-2" src="./assets/flags/{{lang.languageFlag}}.png" alt="{{language.languageName}}"></a>
                </li>
            </ul>
        </div>
    </span>
</nav>
