<form (ngSubmit)="submitRequest()" #requestForm="ngForm">
    <div class="row">
        <div class="offset-1 col-10 p-3 mb-3 form-group">
            <label for="summary">{{ 'form.summary' | translate }}</label>
            <input type="text" class="form-control" id="summary" required [(ngModel)]="request.summary" name="summary" #summary="ngModel" matTooltip="{{ 'tooltip.summary' | translate }}">
            <div [hidden]="summary.valid || summary.pristine" class="alert alert-danger">{{ 'required.summary' | translate }}</div>
        </div>
    </div>
    <div class="row">
        <div class="offset-1 col-5 p-3 mb-3 form-group">
            <label for="language">{{ 'form.languageRefset' | translate }}</label>
            <select class="form-control" id="language" [(ngModel)]="request.language" name="language" #language="ngModel" matTooltip="{{ 'tooltip.languageRefset' | translate }}">
                <option value="None" selected>{{ 'language.none' | translate }}</option>
                <option value="Belgian Dutch">{{ 'language.beDutch' | translate }}</option>
                <option value="Belgian French">{{ 'language.beFrench' | translate }}</option>
                <option value="Danish">{{ 'language.danish' | translate }}</option>
                <option value="Estonian">{{ 'language.estonian' | translate }}</option>
                <option value="IE English">{{ 'language.ieEnglish' | translate }}</option>
                <option value="GB English">{{ 'language.gbEnglish' | translate }}</option>
                <option value="Māori">{{ 'language.maori' | translate }}</option>
                <option value="Norwegian Bokmal">{{ 'language.norwegianBokmal' | translate }}</option>
                <option value="Norwegian Nyorsk">{{ 'language.norwegianNyorsk' | translate }}</option>
                <option value="NZ English">{{ 'language.nzEnglish' | translate }}</option>
                <option value="Swedish">{{ 'language.swedish' | translate }}</option>
                <option value="Swiss German">{{ 'language.swissGerman' | translate }}</option>
                <option value="Swiss Italian">{{ 'language.swissItalian' | translate }}</option>
                <option value="Swiss French">{{ 'language.swissFrench' | translate }}</option>
                <option value="US English">{{ 'language.usEnglish' | translate }}</option>
            </select>
        </div>
        <div class="col-5 p-3 mb-3 form-group">
            <label for="context">{{ 'form.contextRefset' | translate }}</label>
            <select class="form-control" id="context" [(ngModel)]="request.context" name="context" #context="ngModel" matTooltip="{{ 'tooltip.contextRefset' | translate }}">
                <option value="None" selected>{{ 'context.none' | translate }}</option>
                <option value="BE GP - Dutch">{{ 'context.beGPDutch' | translate }}</option>
                <option value="BE GP - French">{{ 'context.beGPFrench' | translate }}</option>
                <option value="NZ Patient Friendly - English">{{ 'context.nzPatientFriendlyEnglish' | translate }}</option>
                <option value="SE Chief Complaints">{{ 'context.seChiefComplaints' | translate }}</option>
                <option value="SE Dose Unit Abbr.">{{ 'context.seDoseUnitAbbr' | translate }}</option>
                <option value="SE Lab Medicine">{{ 'context.seLabMedicine' | translate }}</option>
                <option value="SE Dose Unit Plural">{{ 'context.seDoseUnitPlural' | translate }}</option>
                <option value="SE Medication Prof.">{{ 'context.seMedicationProf' | translate }}</option>
                <option value="SE Medication Common">{{ 'context.seMedicationCommon' | translate }}</option>
            </select>
        </div>
    </div>
    <div class="form-break mb-2"></div>
    <div class="row">
        <div class="offset-1 col-10 p-3 mb-0 form-group">
            <label for="concept">{{ 'form.concept' | translate }}</label>
            <div class="input-group">
                <input type="text" class="form-control" id="concept" [(ngModel)]="request.concept" name="concept" [ngbTypeahead]="search" (selectItem)="populateConceptFields($event)" placeholder="{{ 'form.placeholder' | translate }}" matTooltip="{{ 'tooltip.concept' | translate }}">
                <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
        </div>
        <div class="offset-1 col-5 p-3 mb-3 form-group">
            <label for="conceptId">{{ 'form.conceptID' | translate }}</label>
            <input type="text" class="form-control" id="conceptId" required [(ngModel)]="request.conceptId" name="conceptId" #conceptId="ngModel" matTooltip="{{ 'tooltip.conceptID' | translate }}">
            <div [hidden]="conceptId.valid || conceptId.pristine" class="alert alert-danger">{{ 'require.conceptID' | translate }}</div>
        </div>
        <div class="col-5 p-3 mb-3 form-group">
            <label for="conceptName">{{ 'form.conceptName' | translate }}</label>
            <input type="text" class="form-control" id="conceptName" required [(ngModel)]="request.conceptName" name="conceptName" #conceptName="ngModel" matTooltip="{{ 'tooltip.conceptName' | translate }}">
            <div [hidden]="conceptName.valid || conceptName.pristine" class="alert alert-danger">{{ 'require.conceptName' | translate }}</div>
        </div>
        <div class="offset-1 col-5 p-3 mb-0 form-group">
            <label for="existingDescription">{{ 'form.existingDescription' | translate }}</label>
            <input type="text" class="form-control" id="existingDescription" required [(ngModel)]="request.existingDescription" name="existingDescription" #existingDescription="ngModel" matTooltip="{{ 'tooltip.existingDescription' | translate }}">
            <div [hidden]="existingDescription.valid || existingDescription.pristine" class="alert alert-danger">{{ 'required.existingDescription' | translate }}</div>
        </div>
        <div class="col-5 p-3 mb-3 form-group">
            <label for="newDescription">{{ 'form.newDescription' | translate }}</label>
            <input type="text" class="form-control" id="newDescription" required [(ngModel)]="request.newDescription" name="newDescription" #newDescription="ngModel" matTooltip="{{ 'tooltip.newDescription' | translate }}">
            <div [hidden]="newDescription.valid || newDescription.pristine" class="alert alert-danger">{{ 'required.newDescription' | translate }}</div>
        </div>
    </div>
    <div class="form-break mb-2"></div>
    <div class="row">
        <div class="offset-1 col-10 p-3 mb-0 form-group">
            <label for="justification">{{ 'form.justification' | translate }}</label>
            <input type="text" class="form-control" id="justification" [(ngModel)]="request.justification" name="justification" matTooltip="{{ 'tooltip.justification' | translate }}">
        </div>
        <div class="offset-1 col-10 p-3 mb-0 form-group">
            <label for="reference">{{ 'form.reference' | translate }}</label>
            <input type="text" class="form-control" id="reference" [(ngModel)]="request.reference" name="reference" matTooltip="{{ 'tooltip.reference' | translate }}">
        </div>
    </div>
    <div class="text-end">
        <button type="button" class="btn btn-secondary p-3 px-5" (click)="requestForm.reset()">{{ 'form.reset' | translate | uppercase}}</button>
        <button type="submit" class="btn btn-success p-3 px-5 m-5" [disabled]="!requestForm.form.valid">{{ 'form.submit' | translate | uppercase}}</button>
    </div>
</form>
