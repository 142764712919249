<div class="row rounded form-panel">
    <div class="col-12 p-3 bg-slate-grey rounded-top header-block">
        <h2 class="text-white mb-0">{{ 'requestDetails.title' | translate | uppercase}}</h2>
    </div>
    <div class="col-12 p-0">
        <mat-radio-group class="row p-3" [(ngModel)]="requestType">
            <div class="col-3 mb-3">
                <mat-radio-button id="addConceptFormButton" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'addConcept'">{{ 'requestDetails.addConcept' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="addDescriptionFormButton" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'addDescription'">{{ 'requestDetails.addDescription' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="addRelationshipFormButton" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'addRelationship'">{{ 'requestDetails.addRelationship' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="changeDescriptionFormButton" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'changeDescription'">{{ 'requestDetails.changeDescription' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="changeRelationshipFormButton" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'changeRelationship'">{{ 'requestDetails.changeRelationship' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="inactivateDescription" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'inactivateDescription'">{{ 'requestDetails.inactivateDescription' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="inactivateRelationship" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'inactivateRelationship'">{{ 'requestDetails.inactivateRelationship' | translate | uppercase }}</mat-radio-button>
            </div>
            <div class="col-3 mb-3">
                <mat-radio-button id="otherFormButton" class="text-center p-3 m-0 w-100 bg-ghost-grey rounded-lg" [value]="'other'">{{ 'requestDetails.other' | translate | uppercase }}</mat-radio-button>
            </div>
        </mat-radio-group>

        <app-add-concept-form *ngIf="requestType === 'addConcept'"></app-add-concept-form>
        <app-add-description-form *ngIf="requestType === 'addDescription'"></app-add-description-form>
        <app-add-relationship-form *ngIf="requestType === 'addRelationship'"></app-add-relationship-form>
        <app-change-description-form *ngIf="requestType === 'changeDescription'"></app-change-description-form>
        <app-change-relationship-form *ngIf="requestType === 'changeRelationship'"></app-change-relationship-form>
        <app-inactivate-description-form *ngIf="requestType === 'inactivateDescription'"></app-inactivate-description-form>
        <app-inactivate-relationship-form *ngIf="requestType === 'inactivateRelationship'"></app-inactivate-relationship-form>
        <app-other-form *ngIf="requestType === 'other'"></app-other-form>
    </div>
</div>
