<div class="row rounded form-panel mb-5">
    <div class="col-12 p-3 bg-slate-grey rounded-top header-block">
        <h2 class="text-white mb-0">{{ 'authentication.title' | translate | uppercase }}</h2>
    </div>
    <div class="col-12 py-3">
        <h3 class="text-center text-river-bed">
            <ng-container>{{ 'authentication.description.0' | translate }}</ng-container>
            <a href="https://forms.gle/bcHWqRLMtorgC65s7">{{ 'authentication.description.1' | translate | uppercase }}</a>
            <ng-container>{{ 'authentication.description.2' | translate }}</ng-container>
        </h3>
<!--        <h3 class="text-center text-river-bed">{{ 'authentication.description' | translate:{here: 'authentication.here' | translate | uppercase} }}<a href="https://forms.gle/bcHWqRLMtorgC65s7">{{ 'authentication.here' | translate}} | uppercase }}</a></h3>-->
        <div class="row rounded form-panel">
            <div class="offset-1 col-5 p-3 mb-0 form-group">
                <label for="username">{{ 'authentication.username' | translate }}</label>
                <input class="form-control" id="username" required [(ngModel)]="user.username" name="username" (ngModelChange)="updateUser()">
            </div>
            <div class="col-5 p-3 mb-0 form-group">
                <label for="password">{{ 'authentication.password' | translate }}</label>
                <input type="password" class="form-control" id="password" required [(ngModel)]="user.password" name="password" (ngModelChange)="updateUser()">
            </div>
        </div>
    </div>
</div>
