<app-snomed-navbar></app-snomed-navbar>
<main class="container footer-margin pb-5">
    <div class="row mb-5">
        <div class="col">
            <h1 class="text-center text-river-bed p-5">{{'home.title' | translate | uppercase}}</h1>
            <h3 class="text-center text-river-bed">{{'home.description' | translate}}</h3>
        </div>
    </div>
    <app-auth-block [hidden]="authenticated"></app-auth-block>
    <app-request-form></app-request-form>
</main>
<app-snomed-footer></app-snomed-footer>
